import GTMAPI from "@/integration/GTMAPI"
import BleachAPI from "@/integration/BleachAPI"
import StorefrontAPI from "@/integration/StorefrontAPI"

import { Bleach } from "@/main.js"

import {
  priceAdjustmentSources,
  promoTypes,
  discountTypes,
  productTypes,
  quantityStatusTypes
} from "./constants"

import { v4 as uuidv4 } from "uuid"

const contentStore = {
  namespaced: true,

  state: {
    isCartInit: false,
    cartId: null,
    lineItems: [],
    alertActive: false,
    quantityDelta: 0,
    customerGwpOptIn: true
  },

  mutations: {
    SET_CART_INIT: (state, _val) => {
      state.isCartInit = _val
    },

    SET_CART_ID: (state, _val) => {
      state.cartId = _val
    },

    ADD_LINE_ITEMS: (state, lineItems) => {
      state.lineItems = state.lineItems.concat(lineItems)
    },

    SET_LINE_ITEMS: (state, lineItems) => {
      state.lineItems = lineItems
    },

    INCREMENT_LINE_ITEM_QUANTITY: (state, lineItems) => {
      lineItems.forEach(({ lineItemIndex, quantity }) => {
        state.lineItems[lineItemIndex].quantity += quantity
      })
    },

    REMOVE_LINE_ITEM: (state, lineItemIndex) => {
      state.lineItems.splice(lineItemIndex, 1)
    },

    RESET_CART: state => {
      state.lineItems = []
    },

    SET_CUSTOMER_GWP_OPT_IN: (state, _val) => {
      state.customerGwpOptIn = _val
    },

    setAlertActive: (state, _val) => {
      state.alertActive = _val
    },

    setQuantityDelta: (state, _quantityDelta) => {
      state.quantityDelta = _quantityDelta
    }
  },

  actions: {
    /**
     *
     * @param {Object} payload
     * @param {Array<Object>} payload.items - The products (array of objs) to add quantity of (using the full object from Bleach API)
     * @param {Boolean} payload.passive - If 'true', don't announce the quantity increase to the alert
     * @param {Boolean} payload.openCart - if 'true', opens the cart
     */

    ADD_TO_CART(
      { state, getters, commit, dispatch, rootState, rootGetters },
      { items, passive, openCart }
    ) {
      let lineItemId = uuidv4()

      const PRODUCT_ADDITIONS = []
      const PRODUCT_UPDATES = []

      /* Action Quantity is the 'running count' for the cart alert */
      let actionQuantityTotals = []

      let quantityLimit =
        Number(rootGetters["content/GET_SITE_CONFIG"].itemQuantityLimit) || 99

      let quantityLimitedItems = items.map(item => {
        item = { ...item }

        if (
          /* IF the item is 'unique' */
          (item.customData && item.customData.uniqueItem) ||
          /* OR if it is the first of its kind in the cart (ie, no other non-unique items) */
          !getters.getLineItemByProductId(item.product.sys.id) ||
          getters.getLineItems.findIndex(
            lineItem =>
              lineItem.productId === item.product.sys.id &&
              (!lineItem.customData || !lineItem.customData.uniqueItem)
          ) === -1
        ) {
          item.quantity = Math.min(item.quantity, quantityLimit)
          actionQuantityTotals.push(item.quantity)
        } else {
          /* Increment the existing line item (up to the max possible) */
          let currentLineItemQuantity = getters.getLineItemByProductId(
            item.product.sys.id
          ).quantity
          let maxAvailableQuantity = quantityLimit - currentLineItemQuantity
          item.quantity = Math.min(item.quantity, maxAvailableQuantity)
          actionQuantityTotals.push(item.quantity)
        }
        return item
      })

      quantityLimitedItems.forEach(({ product, quantity, customData = {} }) => {
        let { sellingPlanId } = customData

        // Item is 'Addition' (ie, new line) if
        // - it has uniqueItem customData
        // - OR there is no matching product id in cart (or the matching id is also a unique item)
        // - OR there is no matching id/sellingPlan combo
        if (
          getters.getLineItems.findIndex(
            lineItem =>
              lineItem.productId === product.sys.id &&
              (!lineItem.customData || !lineItem.customData.uniqueItem)
          ) === -1 ||
          !getters.getLineItemByProductId(product.sys.id) ||
          (customData && customData.uniqueItem) ||
          getters.getLineItemIndexBySellingPlan(
            product.sys.id,
            sellingPlanId
          ) == -1
        ) {
          PRODUCT_ADDITIONS.push({
            lineItemId,
            productType: product.__typename,
            productId: product.sys.id,
            product,
            quantity,
            ...(Object.keys(customData).length > 0 && {
              customData
            })
          })
        } else {
          // Else it's an 'Update'
          PRODUCT_UPDATES.push({
            lineItemIndex: getters.getLineItemIndexBySellingPlan(
              product.sys.id,
              sellingPlanId
            ),
            quantity
          })
        }
        if (quantity > 0) {
          GTMAPI.trackAddToCart(
            quantity,
            product,
            rootState.account.currency,
            customData,
            openCart ? "cart_preview" : "cart_alert"
          )
        }
      })

      // 'passive' is for use cases where we don't want to trigger the cart alert
      if (!passive)
        // quantity delta is cumulative until the alert is cleared (by the alert component)
        commit(
          "setQuantityDelta",
          state.quantityDelta +
            actionQuantityTotals.reduce((acc, curr) => acc + curr, 0)
        )

      commit("ADD_LINE_ITEMS", PRODUCT_ADDITIONS)

      commit("INCREMENT_LINE_ITEM_QUANTITY", PRODUCT_UPDATES)

      if (openCart) {
        dispatch("OPEN_CART")
      }

      BleachAPI.setCart(state.cartId, state.lineItems).then(res => {
        dispatch("SET_CART", res.data.setCart)
      })
    },

    /**
     *
     * @param {Object} payload
     * @param {Object} payload.lineItem - The line item to either reduce quantity of, or remove entirely
     * @param {Number} payload.quantity - How much to reduce the quantity by - if undefined, 0 or >= the current line item quantity, removes the line item entirely
     */

    REMOVE_FROM_CART(
      { state, commit, dispatch, getters },
      { lineItem, quantity }
    ) {
      const lineItemIndex = getters.getLineItemIndexById(lineItem.lineItemId)
      if (quantity && quantity > 0 && quantity < lineItem.quantity) {
        commit("INCREMENT_LINE_ITEM_QUANTITY", [
          {
            lineItemIndex,
            quantity: -quantity
          }
        ])
      } else {
        commit("REMOVE_LINE_ITEM", lineItemIndex)
      }

      BleachAPI.removeItemFromCart(
        state.cartId,
        lineItem.lineItemId,
        quantity || lineItem.quantity
      ).then(res => {
        dispatch("SET_CART", res.data.removeLineItem)
      })
    },

    SET_CART({ commit, rootGetters }, payload) {
      const { shoppingCart } = payload

      if (shoppingCart == null) {
        // If cart has been invalidated
        initNewCart()
      } else {
        if (shoppingCart.lineItems) {
          commit("RESET_CART")
          for (let i = 0; i < shoppingCart.lineItems.length; i++) {
            let lineItem = shoppingCart.lineItems[i]
            let product = rootGetters["content/GET_PRODUCT_BY_ECOM_ID"](
              lineItem.ecomVariantId
            )

            try {
              if (validateLineItem(lineItem, product, shoppingCart)) {
                // if the product is valid, deal with it

                let customData = lineItem.customAttributes || []

                if (customData) {
                  customData = customData.reduce((acc, curr) => {
                    acc[curr.key] = curr.value
                    return acc
                  }, {})
                }

                commit("ADD_LINE_ITEMS", [
                  {
                    lineItemId: lineItem.id,
                    productType: product.__typename,
                    productId: product.sys.id,
                    product: product,
                    customData,
                    quantity: lineItem.quantity
                  }
                ])
              }
            } catch {
              // if for some reason the product doesn't exist, reset the cart
              initNewCart()
            }
          }
        }
      }
      function initNewCart() {
        commit("RESET_CART")
        BleachAPI.createNewCart().then(({ data }) => {
          const id = data.addShoppingCart.shoppingCart.id
          commit("SET_CART_ID", id)
        })
      }
    },

    GET_CART_BY_ID({ state, dispatch, commit }, payload) {
      let cartId = payload || state.cartId

      if (cartId) {
        commit("SET_CART_ID", cartId)

        return BleachAPI.getShoppingCartById(cartId).then(res =>
          dispatch("SET_CART", res.data)
        )
      } else {
        commit("RESET_CART")
        return BleachAPI.createNewCart().then(({ data }) => {
          const id = data.addShoppingCart.shoppingCart.id
          commit("SET_CART_ID", id)
        })
      }
    },

    OPEN_CART({ commit }) {
      commit("ui/SET_NAV_ACTIVE_ELEMENT", "cart", {
        root: true
      })
    },

    CLOSE_CART({ commit }) {
      commit("ui/SET_NAV_ACTIVE_ELEMENT", null, {
        root: true
      })
    },

    INIT_GWP_PROMO(/* { state, commit } */) {
      // Find the (first) 'VALUE' promo in the config and set that as the default GWP promo
    },

    CHECKOUT({ state, dispatch, getters, rootState, rootGetters }) {
      let checkoutPayload = [...getters.getLineItems]

      if (getters.getIsGwpPromoTriggered && state.customerGwpOptIn === true) {
        let promotionalGifts = getters.getGwpPromo.promotionalProductsCollection.items
          .filter(item => !!item)
          .flat()
          .filter(item => !!item && item.__typename === "Product")
          .map(reference => {
            return rootGetters["content/GET_PRODUCT_ENTRY"](
              reference.sys.id,
              reference.__typename
            )
          })
          .filter(giftItem => !!giftItem) // ensure gifts are valid (extant) products

        for (const gift of promotionalGifts) {
          checkoutPayload.push({
            productType: gift.__typename,
            productId: gift.sys.id,
            product: gift,
            quantity: 1
          })
        }
      }

      const payloadParams = {
        ...rootState.queryParams.queryParamsMap,
        ...(rootGetters["content/GET_HAS_GLOBAL_PRICE_ADJUSTMENT"] && {
          discount: rootState.content.globalDiscount.discountCode
        })
      }
      dispatch("queryParams/CLEAR_PARAMS", null, { root: true })

      StorefrontAPI.checkout(
        checkoutPayload,
        rootState.locale.locale,
        rootState.identity.userDetails
          ? rootState.identity.userDetails.email
          : null,
        rootState.identity.buuid ? rootState.identity.buuid : null,
        state.cartId,
        payloadParams
      )
    }
  },

  getters: {
    getIsCartActive: (state, getters, rootState) =>
      rootState.ui.navActiveElement === "cart",

    // Filters invalid products, useful if product entries are unpublished but still in customer's cart
    getLineItems: state => state.lineItems.filter(lineItem => lineItem.product),

    getAdjustedLineItems: (state, getters, rootState, rootGetters) =>
      state.lineItems
        .filter(lineItem => lineItem.product)
        .map(lineItem => {
          let adjustmentSource,
            adjustmentType,
            adjustmentValue,
            adjustmentAmount,
            adjustedUnitPrice,
            adjustmentMessage,
            hasPriceAdjustment = false

          // 1. Calculate any price adjustment from subscription data
          if (lineItem.customData && lineItem.customData.sellingPlanId) {
            hasPriceAdjustment = true
            adjustmentSource = priceAdjustmentSources.SUBSCRIPTION
            adjustmentType = discountTypes.PERCENTAGE
            adjustmentValue = lineItem.customData.adjustmentValue
            adjustedUnitPrice =
              lineItem.product.price.cents * ((100 - adjustmentValue) / 100)
          }

          // or 2. Calculate any price adjustment from active MATCH discounts
          const _promoData = rootGetters["content/GET_PROMO_DATA_BY_PRODUCT"](
            lineItem.productId
          )
          if (_promoData && _promoData.getIsTriggered) {
            hasPriceAdjustment = true
            adjustmentSource = priceAdjustmentSources.PROMO
            adjustmentType = _promoData.promo.discountType
            adjustmentValue =
              adjustmentType == discountTypes.PERCENTAGE
                ? _promoData.promo.discountValue
                : null
            adjustmentAmount =
              adjustmentType == discountTypes.FIXED
                ? _promoData.promo.discountValue
                : null
            adjustedUnitPrice = (() => {
              if (adjustmentValue) {
                return (
                  lineItem.product.price.cents * ((100 - adjustmentValue) / 100)
                )
              } else if (adjustmentAmount) {
                return lineItem.product.price.cents - adjustmentAmount / 100
              }
              return null
            })()
            adjustmentMessage = _promoData.promo.description
          }

          // ...or 3. Calculate any price adjustment from global discounting (OVERRIDES ALL OTHER DISCOUNTS)
          if (
            lineItem.productType !== productTypes.GIFT_CARD &&
            rootGetters["content/GET_HAS_GLOBAL_PRICE_ADJUSTMENT"]
          ) {
            hasPriceAdjustment = true
            adjustmentSource = priceAdjustmentSources.GLOBAL
            adjustmentType = discountTypes.PERCENTAGE
            adjustmentValue = rootState.content.globalDiscount.discountValue
            adjustedUnitPrice =
              lineItem.product.price.cents * ((100 - adjustmentValue) / 100)
            adjustmentMessage = null
          }
          return {
            ...lineItem,
            ...(hasPriceAdjustment && {
              priceAdjustment: {
                adjustmentSource,
                adjustmentType,
                adjustmentValue,
                adjustmentAmount,
                adjustedUnitPrice,
                adjustmentMessage
              }
            })
          }
        }),

    getLineItemByProductId: (state, getters) => id =>
      getters.getLineItems.find(lineItem => lineItem.productId === id),

    getLineItemIndexBySellingPlan: (state, getters) => (id, sellingPlanId) =>
      getters.getLineItems.findIndex(
        lineItem =>
          lineItem.productId === id && matchSellingPlan(lineItem, sellingPlanId)
      ),

    getLineItemIndexById: (state, getters) => id =>
      getters.getLineItems.findIndex(lineItem => lineItem.lineItemId === id),

    getCartTotalCents: (state, getters) => sumLineItems(getters.getLineItems),

    getCartAdjustedTotalCents: (state, getters) =>
      sumAdjustedLineItems(getters.getAdjustedLineItems),

    getPromoApplicableTotalCents: (state, getters) => {
      // filter out products that don't count towards the min purchase value on GWP offers. Currently only gift cards but this can now be extended.
      let applicableLines = getters.getAdjustedLineItems.filter(
        lineItem => ![productTypes.GIFT_CARD].includes(lineItem.productType)
      )
      return sumAdjustedLineItems(applicableLines)
    },

    getGwpPromo: (state, getters, rootState, rootGetters) => {
      if (!rootGetters["content/GET_HAS_GLOBAL_PRICE_ADJUSTMENT"]) {
        const _promotions =
          rootGetters["content/GET_SITE_CONFIG"].promotionsCollection.items ||
          []
        const _firstFoundValuePromo = _promotions.find(
          promo => promo.promotionType == promoTypes.VALUE
        )
        return _firstFoundValuePromo
      }
      return null
    },

    getGwpPromoThresholdCents: (state, getters) => {
      const promo = getters.getGwpPromo
      return promo && getters.getGwpPromo.threshold * 100
    },

    getIsGwpPromoTriggered: (state, getters) => {
      const promo = getters.getGwpPromo
      if (promo) {
        if (promo.promotionType == promoTypes.VALUE) {
          return (
            getters.getPromoApplicableTotalCents >=
            getters.getGwpPromoThresholdCents
          )
        } /*  else if (promo.promotionType == promoTypes.QUERY) {
          const parseQuery = function(query = {}, items) {
            // ONLY TAKE FIRST PROPERTY FROM EACH OBJECT
            let key = Object.keys(query) && Object.keys(query)[0]
            let comp = Object.values(query) && Object.values(query)[0]

            if (!promo.matchProperty || !key || !comp) return false

            // ONLY SUPPORTS $and, $or and $in
            if (/^[$](and|or|in)$/.test(key) && Array.isArray(comp)) {
              // IF $in, check if the input array shares any items with the query
              if (key === "$in") {
                return items.some(item => comp.includes(item))
              }

              let results = []

              for (let i of comp) {
                let valid = parseQuery(i, items)

                // SHORT-CIRCUIT the operations to save time (for what little it's worth)
                if (key === "$and" && !valid) return false
                if (key === "$or" && valid) return true

                results.push(valid)
              }

              // RETURN the result of the operator
              if (key === "$and" && results.some(x => !x)) return false
              if (key === "$or" && results.every(x => !x)) return false
              return true
            }
            return false
          }

          const _items = getters.getLineItems.map(
            lineItem => lineItem.product[promo.matchProperty]
          )

          return parseQuery(promo.matchQuery, _items)
        } */
      }
      return false
    },

    getCartTotalQuantity: (state, getters) => {
      let lineItemQuantities = getters.getLineItems.map(
        lineItem => lineItem.quantity
      )
      return lineItemQuantities.reduce((total, quantity) => quantity + total, 0)
    }
  }
}

function sumLineItems(lineItems) {
  return lineItems.reduce((total, lineItem) => {
    return (
      ((lineItem.product.compareAtPrice &&
        lineItem.product.compareAtPrice.cents) ||
        lineItem.product.price.cents) *
        lineItem.quantity +
      total
    )
  }, 0)
}

function sumAdjustedLineItems(lineItems /* , siteConfig */) {
  return lineItems.reduce((total, lineItem) => {
    if (lineItem.priceAdjustment) {
      return (
        lineItem.priceAdjustment.adjustedUnitPrice * lineItem.quantity + total
      )
    }
    return lineItem.product.price.cents * lineItem.quantity + total
  }, 0)
}

function matchSellingPlan(lineItem, sellingPlanId) {
  if (lineItem.customData && lineItem.customData.sellingPlanId) {
    return sellingPlanId == lineItem.customData.sellingPlanId
  } else {
    return !sellingPlanId
  }
}

function validateLineItem(lineItem, product, cart) {
  if (!lineItem || !product) {
    Bleach.debug.error(
      "Line Item or Product missing, unable to validate",
      lineItem,
      product,
      cart
    )
  }

  const _isValidItem =
    !!lineItem &&
    !!lineItem.ecomVariantId &&
    !!lineItem.id &&
    lineItem.quantity > 0

  const _isValidProduct =
    !!product.activeThisStore &&
    // If showOnStorefront is not set (backwards compatibility)
    (product.showOnStorefront == true ||
      product.showOnStorefront == null ||
      [productTypes.SET, productTypes.GIFT_CARD].includes(product.__typename))
  !product.waitlist &&
    product.quantityStatus != quantityStatusTypes.OUT_OF_STOCK

  if (!_isValidItem || !_isValidProduct) {
    Bleach.debug.error(
      `Invalid ${!_isValidItem ? "lineItem" : "product"} in cart:`,
      lineItem,
      product,
      cart
    )
    return false
  } else {
    return true
  }
}

export default contentStore
